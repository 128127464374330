











































































































import { RawTriggerData } from '@/components/TriggerSetup/logic/types/types'
import TriggerCard from "@/components/TriggerSetup/TriggerCard.vue";
import TriggerCardInterfaceMixin from "@/components/TriggerSetup/TriggerCardInterfaceMixin";
import { ActiveChatTriggers } from "@/includes/types/triggers";

import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue';

import { Component, Mixins, Prop } from 'vue-property-decorator'
import draggable from "vuedraggable";
import isMobile from '@/assets/utils/isMobile';

@Component({
  components: {
    TriggerCard,
    List,
    NumberInput,
    draggable,
    Icon,
    EmptyData
  },
  data() {
    return {
      ListDirection
    }
  }
})
export default class TriggerCardListWrapper extends Mixins(ModelSetter, TriggerCardInterfaceMixin) {
  @Prop() triggers!: Array<RawTriggerData>

  @Prop() allTriggers!: ActiveChatTriggers

  changeIndexModalOpen = false

  newIndex = 1

  selectedTrigger: { index: number, trigger: RawTriggerData } | null = null

  modalOkClick() {
    if (this.selectedTrigger?.trigger?.id) {
      this.moveTriggerIndex(
        this.selectedTrigger.trigger.id,
        this.newIndex - 1,
        () => {
          this.changeIndexModalOpen = false
        })
    }
  }

  selectTrigger(payload: { index: number, trigger: RawTriggerData } | undefined) {
    if (payload) {
      this.selectedTrigger = payload
      this.newIndex = payload.index

      this.changeIndexModalOpen = true
    }
  }

  onEnd(added: { item: Element, oldIndex: number, newIndex: number }) {
    const { item, oldIndex, newIndex } = added

    if (oldIndex !== newIndex) {
      this.moveTriggerIndex(item.id, added.newIndex)
    }
  }

  listItemStyles(id: RawTriggerData['id']): Partial<CSSStyleDeclaration> {
    let borderLeftColor = ''

    if (this.updatedTriggerId === id) {
      borderLeftColor = 'rgba(var(--a-warning), 1)'
    } else if (this.isActive(id)) {
      borderLeftColor = 'rgba(var(--a-success), 1)'
    } else {
      borderLeftColor = 'rgba(var(--a-danger), 1)'
    }

    return {
      borderLeft: `3px solid ${ borderLeftColor }`
    }
  }

  get options() {
    if (isMobile()) {
      return {
        delay: 300
      }
    }

    return {}
  }
}
