




























































































































import TriggersListSectionTitle from "@/components/TriggerSetup/TriggersListSectionTitle.vue";
import TriggerCardListWrapper from "@/components/TriggerSetup/TriggerCardListWrapper.vue";
import { NetworkConfig } from "@/includes/types/networks";
import {
  deleteNetworkTrigger,
  getNetworkTriggers,
  isConfigChanged,
  setNetworkConfig
} from "@/includes/logic/Networks/logic";
import TriggersListMixin from "@/mixins/TriggersListMixin";
import { InputSetups } from "@/mixins/input-setups";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";
import { inactiveEntitiesChats } from "@/includes/logic/Networks/utils";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';
import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue';
import TokenId from "piramis-base-components/src/shared/utils/TokenId";
import CenteredColumnLayout from "piramis-base-components/src/components/CenteredColumnLayout.vue";

import { Component, Mixins } from 'vue-property-decorator';
import arrayMove from "array-move";
import { cloneDeep } from "lodash";
import { NavigationGuardNext } from "vue-router/types/router";
import { Route } from "vue-router";

Component.registerHooks([ 'beforeRouteLeave' ])

@Component({
  components: {
    SearchTextInput,
    PageTitle,
    TriggersListSectionTitle,
    TriggerCardListWrapper,
    CenteredColumnLayout
  }
})
export default class NetworkTriggers extends Mixins(TriggersListMixin, InputSetups, NetworksHelperMixin) {

  networkConfig: NetworkConfig | null = null

  get readonlyNetwork() {
    return this.$store.getters.readonlyNetwork
  }

  get groupsWithoutTriggers() {
    const network = this.$store.state.networksState.currentNetwork

    if (network) {
      return inactiveEntitiesChats(network, 'group_without_triggers')
    }
  }

  get triggers(): RawTriggerData[] {
    return this.$store.state.networksState.triggers.triggers ?? []
  }

  get searchTriggers() {
    if (this.networkConfig) {
      return this.queriedTriggers(this.triggers ?? [], this.triggersSortFn)
    }

    return []
  }

  get inactiveTriggers() {
    if (this.networkConfig) {
      const { triggers } = this.networkConfig

      if (triggers) {
        return this.triggers.filter(t => !triggers.includes(+TokenId.getId(t.id!)))
      }
    }

    return []
  }

  get sortedActiveTriggers() {
    const activeTriggerIds = this.networkConfig?.triggers ?? []

    return cloneDeep(this.triggers)
      .filter(t => activeTriggerIds.includes(+TokenId.getId(t.id!)))
      .sort(this.triggersSortFn)
  }

  isTriggerActive(id: RawTriggerData['id']) {
    return this.sortedActiveTriggers.some(t => t.id === id)
  }

  editTrigger(id: string) {
    this.gotoNetworkTrigger('edit', id)
  }

  copyTrigger(id: string) {
    if (this.triggers) {
      const idx = this.triggers.findIndex(t => t.id === id)

      if (idx !== -1) {
        const trigger = cloneDeep(this.triggers[idx])

        delete trigger.id
        delete trigger.owner

        this.$store.commit('SET_TRIGGER_TO_COPY', trigger)

        this.gotoNetworkTrigger('new')
      }
    }
  }

  triggersSortFn(a: RawTriggerData, b:RawTriggerData) {
    const activeTriggerIds = this.networkConfig?.triggers ?? []

    return activeTriggerIds.indexOf(+TokenId.getId(a.id!)) - activeTriggerIds.indexOf(+TokenId.getId(b.id!))
  }

  saveNetworkConfigVm() {
    if (this.networkConfig) {
      this.$baseTemplate.loader.open()

      return setNetworkConfig(
        this.networkConfig.id,
        {
          triggers: this.networkConfig.triggers
        })
        .then((res) => {
          this.$baseTemplate.loader.close()

          if (res) {
            this.networkConfig = res

            return res
          }
        })
    }

    return Promise.resolve()
  }

  removeTrigger(id: string) {
    if (this.networkConfig) {
      deleteNetworkTrigger(this.networkConfig.id, id)
        .then(res => {
          if (res) {
            this.networkConfig = res
          }
        })
    }
  }

  removeFromActive(id: string) {
    if (this.networkConfig) {
      const idx = this.networkConfig.triggers.findIndex(t => t === +TokenId.getId(id))

      this.networkConfig.triggers.splice(idx, 1)
    }
  }

  addToActive(id: string) {
    if (this.networkConfig) {
      const shortId = +TokenId.getId(id)

      this.networkConfig.triggers.push(shortId)
    }
  }

  moveToDirection(data: { id: string, direction: 'left' | 'right' }): void {
    if (this.networkConfig) {
      const { triggers } = this.networkConfig
      const { id, direction } = data

      const index = triggers.indexOf(+TokenId.getId(id))

      this.networkConfig.triggers = arrayMove(triggers, index, direction === 'left' ? index - 1 : index + 1)
    }
  }

  moveToIndex(data: { id: string, newIndex: number }): void {
    if (this.networkConfig) {
      const { triggers } = this.networkConfig
      const { id, newIndex } = data
      const index = triggers.indexOf(+TokenId.getId(id))

      this.networkConfig.triggers = arrayMove(triggers, index, newIndex)
    }
  }

  beforeRouteLeave(to: Route, from:Route, next: NavigationGuardNext) {
    if (this.networkConfig && isConfigChanged(this.networkConfig)) {
      this.$confirm({
        title: this.$t('confirm_save_before_leave_title').toString(),
        content: this.$t('confirm_save_before_leave_content').toString(),
        cancelText: this.$t('confirm_save_before_leave_cancel').toString(),
        okText: this.$t('confirm_save_before_leave_ok').toString(),
        onOk: () => {
          this.saveNetworkConfigVm()
            .then(res => {
              if (res) {
                next()
              }
            })
        },
        onCancel: () => {
          next()
        }
      })
    } else {
      next()
    }
  }

  created() {
    this.networkConfig = cloneDeep(this.$store.state.networksState.currentNetwork)

    if (this.networkConfig) {
      getNetworkTriggers(this.networkConfig.id)
    }
  }
}
