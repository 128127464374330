







































































import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
})
export default class TriggersListSectionTitle extends Vue {
  @Prop({ type: String, default: null }) title!:string

  @Prop({ type: String, default: null }) helpMessage!:string

  @Prop({ type: String, default: null }) subtitle!:string

  @Prop({ type: Boolean }) showAddButton!:boolean

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasStatisticsButton!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasImportButton!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasRestoreButton!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasDisableAllButton!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasDeleteAllButton!: boolean

  @Emit()
  addTriggerClick(): void {}

  @Emit()
  statisticsClick(): void {}

  @Emit()
  selectDropdownItem({ key }: { key: string }) {
    return key
  }
}
