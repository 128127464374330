
































































































































































































































































































import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import TriggerCardInterfaceMixin from "@/components/TriggerSetup/TriggerCardInterfaceMixin";

import { tooltipTrigger } from "piramis-base-components/src/shared/utils/tooltipTrigger";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';

@Component({
  methods: {
    tooltipTrigger
  },
  components: {
    Icon
  }
})
export default class TriggerCard extends Mixins(TriggerCardInterfaceMixin) {
  @Prop() item!: RawTriggerData

  @Prop() isUpdated!: boolean

  @Prop() index!: number

  @Emit()
  selectTrigger(): { trigger: RawTriggerData, index: number } | undefined {
    if (!this.readonly) {
      return {
        trigger: this.item,
        index: this.index
      }
    }
  }

  get isActiveTrigger(): boolean {
    return this.isActive(this.item?.id ?? '')
  }
}
