import { ConditionsType, RawTriggerData } from "@/components/TriggerSetup/logic/types/types";

import { trimAndLow } from "piramis-base-components/src/shared/utils/trimAndLow";

import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class TriggersListMixin extends Vue {
  triggersHighlightTo: Record<string, Array<Array<string>>> = {}

  searchQuery = ''

  @Watch('searchQuery')
  onSearchQueryChange(value: string) {
    if (!value.trim()) {
      this.triggersHighlightTo = {}
    }
  }

  queriedTriggers(triggers: Array<RawTriggerData>, sortFn?: (a:RawTriggerData, b:RawTriggerData) => number): Array<RawTriggerData> {
    const nameFilterTriggers = (triggers ?? [])
        .filter((t) => t.name.toLowerCase().includes(trimAndLow(this.searchQuery)))

    if (!nameFilterTriggers.length) {
      return (triggers ?? [])
          .filter((t) => this.findTriggerConditions(t, this.searchQuery))
          .sort(sortFn)
    }

    this.triggersHighlightTo = {}

    return nameFilterTriggers.sort(sortFn)
  }

  findTriggerConditions(trigger:RawTriggerData, query: string) {
    const conditionWithChipValues = [
      ConditionsType.MessageTextFullMatch,
      ConditionsType.MessageTextEndWith,
      ConditionsType.MessageTextStartWith,
      ConditionsType.MessageTextSubstringMatch,
      ConditionsType.MessageWordStartWith,
      ConditionsType.MessageWordEndWith,
      ConditionsType.MessageWordFullMatch
    ]

    const conditions = trigger.conditions.find((group) => group.some((c) => conditionWithChipValues.includes(c.version === "v1" ? c.type : c.condition_type) && c?.value.map(trimAndLow).some((v:any) => v.includes(trimAndLow(query)))))

    if (conditions !== undefined) {
      this.$set(this.triggersHighlightTo, trigger.id ?? '', conditions.map((c) => c.value.filter((v: string) => v.includes(trimAndLow(query)))).filter(v => v.length))

      return true
    }

    this.$delete(this.triggersHighlightTo, trigger.id ?? '')

    return false
  }
}
