import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";

import { Component, Emit, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class TriggerCardInterfaceMixin extends Vue {
  @Prop({ type: Function }) hasLimits!: (item: RawTriggerData) => string

  @Prop() triggersHighlightTo!: Array<string>

  @Prop() updatedTriggerId!: string | null | undefined

  @Prop({ type: Boolean, required: false, default: false }) readonly!: boolean

  @Prop({ type: Function, required: true }) isActive!: (id: RawTriggerData['id']) => boolean

  @Emit()
  removeTrigger(id: string): string {
    return id
  }

  @Emit()
  addToActive(id: string): string {
    return id
  }

  @Emit()
  removeFromActive(id: string): string {
    return id
  }

  @Emit()
  editTrigger(id: string): string {
    return id
  }

  @Emit()
  copyTrigger(id: string): string {
    return id
  }

  @Emit()
  moveTriggerDirection(payload: { id: string, direction: 'left' | 'right' }): { id: string, direction: 'left' | 'right' } {
    return payload
  }

  @Emit()
  moveTriggerIndex(id: string, newIndex: number, callback?: () => void): { id: string, newIndex: number, callback?: () => void } {
    return { id, newIndex, callback }
  }
}
